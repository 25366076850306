export const INPUT = {
  SHOW_INPUT_ERROR: 'SHOW_INPUT_ERROR',
  HIDE_INPUT_ERROR: 'HIDE_INPUT_ERROR',
  SAVE_VALUE: 'SAVE_VALUE',
  CLEAR_SEARCH_VALUES: 'CLEAR_SEARCH_VALUES',
};

export const MODAL = {
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  START_LOADING: 'START_LOADING',
  STOP_LOADING: 'STOP_LOADING',
};

export const RESULTS = {
  SAVE_RESULTS: 'SAVE_RESULTS',
};
